:root {
    --bg_index_pc: url("../img/bg_index_pc.jpg");
    --bg_index_m: url("../img/bg_index_m.jpg");
    --bg_intro_pc: url("../img/bg_intro_pc.jpg");
    --bg_intro_m: url("../img/bg_intro_m.jpg");
    --bg_intro_text: #ffffff;
    
    
    --neon-text-light: rgba(255,255,255, .25);
    --neon-text-color: #04f;
    --neon-text-stroke: #4CF1FF;

}

body.indexbg {
    // background-image: var(--bg_index_pc);
    background: var(--bg_index_pc) no-repeat top center;
    background-size: cover;
    background-attachment: fixed;
    .bg_im_1{
        position: fixed;
        top: 0;
        left: -10%;
        z-index: 1;
        width: 30%;
        // mix-blend-mode: overlay;
        filter: brightness(1.5);
        @include animation (0s, 15s, moveball_2, forwards, normal, infinite);
    }
    .bg_im_2{
        position: fixed;
        right: -10%;
        bottom: -20%;
        z-index: 1;
        width: 50%;
        // mix-blend-mode: overlay;
        @include animation (.5s, 10s, moveball_2, normal, normal, infinite);
    }

    @include media-breakpoint-down(md) {
        background-image: var(--bg_index_m);
        background-size: cover;
        background-attachment: fixed;
    }

}

.wrapper.indexWrap {
    position: relative;
    z-index: 2;
    margin-top: 76px;

    @include media-breakpoint-down(lg) {
        margin-top: 70px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 60px;
    }
}
.indexVideo{
    margin: 0;
    padding: 0;
    height: fit-content;
    video{
        margin-bottom: -7px;
    }
}

.bg_intro {
    position: relative;
    // z-index: -1;
    background: var(--bg_intro_pc) no-repeat center center;
    background-size: cover;
    padding:9% 0 12% 0;

    // background-attachment: fixed;
    .row {
        align-items: center;

        .col-12 {

            // padding: 6%;
            &:last-child {
                padding: 0 10%;
            }
        }
    }


    h2,
    h5,
    p {
        color: var(--bg_intro_text);
        margin-bottom: 1rem;
    }

    p {
        font-weight: 200;
    }

    .marquee {
        position: absolute;
        bottom: -26px;
        opacity: .8;
        .marquee >div {
            // display: inline-block;
            // padding-left: 0;       /* 起始位置為容器外部 */
            animation: marquee 25s linear infinite;
            text-transform:uppercase;
            background-color: transparent;
        }

        span {
            filter: contrast(1.3);
            // color: #fff;
            /* 藍色邊框，調整邊框寬度 */
            // mix-blend-mode: screen;

            position: relative;
            z-index: 2;
            width: 100%;
            margin: 0;
            padding-top: 0.15em;
            font-size: 7vw;
            // font-family: "Freeman", sans-serif;
            font-weight: 600;
            line-height: 1;
            color: transparent;
	        -webkit-text-stroke: 1px $primary;
            // -webkit-text-stroke: 1px #fff;
           
        }

        

    }
    @include media-breakpoint-down(md) {
        background-image: var(--bg_intro_m);

        .row {
            .col-12 {
                &:last-child {
                    padding: 10%;
                }
            }
        }
    }
}

.bg_news {
    padding: 11% 5%;

    h2 {
        position: relative;
        color: $cyan-100;
        margin-bottom: 3em;
        .lll{
            position: absolute;
            top: -20px;
            left: 38%;
            width: 50px;
            // transform: rotate(90deg);
        }
    }

    .card.card_vv {
        // width: 30vw;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 1.8em;
        border: 0;
        h5{
            @include text-ellipsis (100%, 2);

        }

        p {

            @include text-ellipsis (100%, 3);
        }

        a {
            text-decoration: none;

            &:hover {
                filter: brightness(1.2);
            }
        }

        .card_img {
            display: flex;
            align-items: center;
            width: 100%;
            height: 10rem;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .btn {
            margin-left: 39%;
        }
    }

    .btn {
        margin-top: 1.5em;
        padding: .5em 2em;
        margin-bottom: 1em;

        &:hover {
            color: $primary;
        }
    }
}

.bg_information {
    position: relative;
    padding: 10% 0;
    background-color: #9ccbd0;
    overflow: hidden;
    
    .bg_im_3{
        position: absolute;
        // top: 10%;
        // left: -15%;
        z-index: 1;
        width: 38%;
        transform:rotate(-30deg);
        @include animation (.5s, 12s, moveball_3, normal, normal, infinite);
    }
    .bg_im_4{
        position: absolute;
        z-index: 1;
        width: 25%;
        transform: rotate(30deg);
        filter: brightness(1.5);
        @include animation (0s, 10s, moveball_4, normal, normal, infinite);
    }
h2{
    position: relative;
    .lll{
        position: absolute;
        top:-10px;
        left: 10%;
        width: 80px;
        span{
            background-color: $primary;
        }
        transform: rotate(90deg);
    }
}

    .container {
        z-index: 9;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 50px;
            margin-left: 50px;
            z-index: 1;
            content: "1234";
            width: 800px;
            height: 500px;
            background-color: #5db2c2;
            display: block;
            border-radius: 50%;
            filter: blur(80px) contrast(1.5);
            opacity: .5;
            @include animation (0s, 3s, moveball_1, normal, normal, infinite);
        }

        &::after {
            position: absolute;
            right: 0;
            bottom: -100px;
            z-index: 0;
            content: "1234";
            width: 1000px;
            height: 700px;
            background-color: $cyan;
            display: block;
            border-radius: 50%;
            filter: blur(80px) contrast(1.5);
            opacity: .5;
            @include animation (0s, 3s, moveball_1, normal, normal, infinite);
        }

    }

    .row {

        position: relative;
        z-index: 2;
    }

    .information_box {
        position: relative;
        z-index: 2;
        background-color: $white;
        padding: .5em 2em;
        border: 10px solid #234C98;
        border-left: 1px;
        border-right: 1px;
        a{
            text-decoration: none;
            color: $gray-800;
            &:hover{
                color: $primary;
            }
            &.btn:hover{
                color: $white;
            }
        }

        ul {
            padding: 0 1.5em;

            li {
                // display: flex;
                position: relative;
                list-style: none;
                height: fit-content;
                // border-left: 5px solid #FB8385;
                border-bottom: 1px solid $gray-200;
                // margin-left: 2em;
                padding-left: 2em;
                margin-top: 2em;
                padding-bottom: 1em;
                // border-radius: 5px; /* 圓角半徑 */

                &:before {
                    position: absolute;
                    left: 0;
                    content: "";
                    width: 5px;
                    height: calc(100% - 2em);
                    background-color: #FB8385;
                    display: inline-block;
                    border-radius: 10px;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
    .information_banner{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 3%;
        @include media-breakpoint-down(xxl){
            justify-content: flex-start;
        }
        @include media-breakpoint-down(lg){
display: none;
        }
    }

    h2 {
        position: relative;
        z-index: 2;
        color: #234C98;
        margin-bottom: 2em;
        text-align: left;
    }

    h5 {
        margin-bottom: .5em;
    }

    p {
        display: flex;
        flex-wrap: wrap;

        span {
            position: relative;
            padding-right: 1em;
            padding-left: 2.5em;
            margin-left: -1em;
            line-height: 2;

            // margin-bottom: .8em;
            i {
                position: absolute;
                margin-right: .5em;
                margin-left: -1.5em;
            }
        }
    }

    .btn {
        margin-bottom: 1em;
        padding: .5em 2em;

        &:hover {
            color: $primary;
        }
    }


    @include media-breakpoint-down(xl) {
        padding: 2em 1em;
    }

    @include media-breakpoint-down(md) {
        .information_box {
            padding: 1rem 0;

        }
    }
}

.bg_case {
    padding: 11% 5%;
    background-color: rgba($color: $cyan, $alpha: .3);

    .caseList {
        padding: 3em;
        border: 1px solid $cyan-100;
        background-color: rgba($color: $cyan-300, $alpha: .3);
        @include media-breakpoint-down(lg){
            padding: 2em 1em;
        }
        .caseItem {
            display: flex;
            flex-wrap: nowrap;
            figure.card.card_vv{
                width: calc(20% - 20px);
                height: 20%;
                margin: 10px;
                .card_img{
                    height: 9vw;
                }
                .card-body{
                    min-height: 3.5em;
                }
                
            }
            @include media-breakpoint-down(lg){
                flex-wrap: wrap;
                    figure.card.card_vv{
                        width: calc(50% - 20px);
                        .card_img{
                            height: 20vw;
                        }
                    } 
                }
            @include media-breakpoint-down(sm){
                figure.card.card_vv{
                    width: calc(100% - 20px);
                    .card_img{
                        height: 35vw;
                    }
                }
            }
        }
    }

    

    h2 {
        position: relative;
        color: $white;
        margin-bottom: 3em;
        .lll{
            position: absolute;
            top: -20px;
            right: 40%;
            width: 50px;
        }
    }

    .card.card_vv {
        // width: 30vw;
        justify-content: center;

        // padding-left: 0;
        // padding-right: 0;
        .card-body {
            padding: .5em .8em;

            p {
                font-weight: 500;
            }

            &:hover {
                p {
                    color: $primary;
                }
            }
        }

        p.card-text {
            // @include text-ellipsis (100%, 1);
            font-size: .8rem;
            text-align: center;
            text-align-last: inherit;
        }

        a {
            text-decoration: none;

            &:hover {
                filter: brightness(1.2);
            }
        }

        .card_img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            overflow: hidden;
            padding: 8px;
            padding-bottom: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include media-breakpoint-down(md) {
                height: 10rem;
            }
        }

        .btn {
            margin-left: 39%;
        }
    }

    .btn {
        margin-top: 3em;
        padding: .5em 1.5em;

        &:hover {
            color: $primary;
        }
    }
    .ttt{
        position: absolute;
        left: 1em;
        bottom: -1.5em;
        color: $cyan;
        font-size: 30px;
        font-weight: 100;
        opacity: .3;
        @include media-breakpoint-down(md){
            font-size: 14px;
            bottom: -2.5em;
        }
    }
}

.marquee_md {
    .marquee >div {
        // display: inline-block;
        // padding-left: 0;       /* 起始位置為容器外部 */
        animation: marquee_md 30s linear infinite;
        text-transform:uppercase;
        background-color: transparent;
    }

    span {
        filter: contrast(1.3);
        // color: #fff;
        /* 藍色邊框，調整邊框寬度 */
        // mix-blend-mode: screen;

        position: relative;
        z-index: 2;
        width: 100%;
        margin: 0;
        padding-top: 0.15em;
        font-size: 6vw;
        font-weight: 600;
        line-height: 1;
        color: transparent;
        -webkit-text-stroke: 1px $cyan;
        // -webkit-text-stroke: 1px #fff;
        opacity: .4;
       
    }

    

}