
.menu-icon {
  position: relative;
  display: none;
  //  z-index: 9999999;
}

.menu-icon span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: $dark;
  transition: all 0.3s ease;
  position: relative;
  //   top: 8px;
  //   left: 10px;
  //   z-index: 9999999;
}

/* 點擊後的動畫 */
.menu-icon.active span:nth-child(1) {
  transform: rotate(45deg);
  top: 8px;
}

.menu-icon.active span:nth-child(2) {
  opacity: 0;
}

.menu-icon.active span:nth-child(3) {
  transform: rotate(-45deg);
  top: -5px;
}

@include media-breakpoint-down(lg) {
  #sidr {
    display: none;
  }
  .menu-icon {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 41px;
    height: 41px;
    border: none;
    // background-color: $cyan;
    cursor: pointer;
    padding: 10px;
    z-index: 9999;

    &.active {

      top: 5px;
      position: fixed;
      // right: 20px!important;
      width: 40px;
      height: 40px;

      span {
        background-color: $white;
      }

    }
  }
  #sidr.sidr {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
    font-size: 15px;
    // background: #333;
    background-color: $primary;
    color: #fff;
    /* box-shadow: 0 0 5px 5px #222 inset */


    .sidr-inner {
      padding: 0 0 15px
    }

    .sidr-inner > p {
      margin-left: 15px;
      margin-right: 15px
    }

    &.right {
      left: auto;
      right: -100%
    }

    &.left {
      left: -100%;
      right: auto
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $dark, $alpha: 0.9);
  display: none; /* 預設隱藏 */
  z-index: 10; /* 高於主內容但低於側邊欄 */
}