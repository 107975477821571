@import url('https://fonts.googleapis.com/css2?family=Freeman&family=Noto+Sans+TC:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
    // background-color: $cyan-100;
    // @include tran(0.2s, ease);
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: $blue-bg;
    // overflow-y: scroll;
    @include animation (0, .5s, fadeIn, forwards, unset, 1);

    &.top_bg {
        background-image: url(../img/topBG.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: 0;
        background-size: 100% auto;
        background-attachment: fixed;

        @media (min-width: 1669px) {
            background-position-y: 0;
        }

        @media (min-width: 1889px) {
            background-position-y: -5%;
        }

        @include media-breakpoint-down(xl) {
            // background: transparent;  
            // ba
            background-position-y: 5%;
            background-size: 130% auto;
        }

        @include media-breakpoint-down(md) {
            // background: transparent;  
            // ba
            background-size: 300% auto;
        }
    }

    h5 {
        font-size: 18px;
    }

    p {
        text-align: justify;
        text-align-last: left;
        font-size: 16px;
        font-weight: 300;
    }

    @include media-breakpoint-down(md) {
        h5 {
            font-size: 16px;
        }

        p {
            text-align: justify;
            text-align-last: left;
            font-size: 14px;
            font-weight: 300;
        }


    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0; // 初始為透明
    }

    50% {
        opacity: 1; // 漸變到可見
    }

    100% {
        opacity: 0; // 初始為透明
    }
}

.lll {
    display: flex; // 使 span 可以水平排列
    flex-direction: column; // 每個 span 垂直排列
    // animation: repeatAnimation 5s infinite; // 設定整體動畫的時間
    opacity: .7;

    span {
        background-color: $white;
        width: 30px;
        height: 2px;
        display: block;
        margin-bottom: 0.2em;
        opacity: 0; // 初始為透明

        // 使用迴圈生成動畫
        @for $i from 1 through 7 {
            &:nth-child(#{$i}) {
                animation: fadeInOut 2s infinite; // 動畫持續時間和效果
                animation-delay: ($i - 1) * 0.4s; // 每個 span 的延遲 (這裡是1.2秒)
            }
        }
    }
}

// 重複整體動畫
@keyframes repeatAnimation {

    0%,
    100% {
        opacity: 0; // 開始和結束時透明
    }

    14% {
        opacity: 1; // 在這個時間點讓所有 span 都顯示出來
    }

    50% {
        opacity: 1; // 中間時所有的 span 都顯示
    }

    86% {
        opacity: 0; // 在這個時間點讓所有 span 都隱藏
    }
}

header {
    position: fixed;
    z-index: 9999;
    // top: 0;
    // left: 2%;
    // width: calc(100% - 4%);
    top: 0;
    width: 100%;
    background-color: $white;
    @include animation (0, .5s, fadeIn, forwards, unset, 1);
    @include shadow(3px, 5px);


    .navBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        .logo {
            width: 50%;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;
            // padding-top: 5px;
            // padding-left: 10px;

            .navbar-brand {
                width: auto;
                // margin-top: 5px;

                img {
                    max-width: 100%;
                    height: 35px;
                }
            }
            @include media-breakpoint-down(xl){
                width: 35%;
                
            }
        }

        .mainNav {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .navBar_list {
            position: relative;
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0;
            // display: none;

            li {
                margin: 0;
                padding: 0;
                list-style: none;

                i {
                    float: right;
                    width: 1em;
                    margin-left: 1em;
                }
            }

            // @include tran(0.5s, ease);
            .nav-item {
                position: relative;
                z-index: 20;
                // padding-bottom: 10px;
            }

            .no_link {
                &.rotate180 {
                    i::before {
                        animation: rrroate .3s linear 1 forwards;
                    }
                }
            }

            .down_menu {
                width: auto;
                display: none;
                background-color: $white;
                margin: 0;
                margin-left: calc(50% - 6em);
                padding: .4em 0;
                border: 1px solid $cyan;
                border-radius: 10px;
                @include shadow(2px, 5px);
                li{
                    // padding: 1em 0;
                }
                .dropdown-item {
                    width: 8em;
                    // padding: .5em 0;
                    color: $gray-800;
                    text-align: center; 

                }
            }

            // NAV PC------- 
            @include media-breakpoint-up(lg) {
                li {
                    i {
                        display: none;
                    }
                }

                .nav-item:hover .down_menu {
                    position: absolute;
                    // top: 0;
                    display: block;
                    margin-top: 0px;
                    // padding: .5em;
                    // padding-top: 10px;

                    &>li {
                        width: 100%;
                        display: block;
                        padding: .35em 2em;
                        border-bottom: 1px solid $blue-bg;

                        a {
                            &:hover {
                                color: $primary;
                                background-color: $white;
                            }
                        }

                        &:last-child {
                            border-bottom: 0;
                        }
                    }

                    // padding-top: 30px;
                    @include animation (0, .3s, fadeIn, forwards, unset, 1);
                }

                .downdown {
                    @include animation (0, .5s, fadeIn, forwards, unset, 1);
                }
                .nav-link{
                    padding: 1rem .95rem;
                    @include media-breakpoint-down(xl){
                        padding: 1rem .7rem;
                        font-size: 15px;
                    }
                }
            }
            

        }

        //NAV M-------      
        @include media-breakpoint-down(lg) {
            padding: 5px;

            // .navBar_list{
            //     display: none;
            // }
            .sidr {
                .navBar_list {
                    display: block;
                    padding-top: 60px;
                    padding-left: 20px;
                    padding-right: 20px;

                    li {
                        margin: .5em;
                        padding: .35em;
                        border-bottom: 1px solid $blue-400;

                        .nav-link {
                            color: $white;
                        }
                    }

                }

                .down_menu {
                    margin-left: 0;
                    border-radius: 10px;

                    li {
                        border: 0;
                        .dropdown-item{
                            text-align: left;
                            padding: 0;
                            padding-left: 1em;
                        }
                    }
                }
            }


            .logo {
                display: flex;
                width: 100%;
                margin-left: 2%;

                .navbar-brand {
                    width: auto;

                    img {
                        max-width: 100%;
                    }
                }
            }

            .mainNav {
                display: block;
                width: auto;
            }
        }
    }


    ///* Search */
    .topSearchBTN {
        font-size: 1.5rem;
        border-radius: 50px;

        &:hover {
            background-color: $cyan;
            color: $white;
        }
    }

    .offcanvas {
        padding: 2rem 1rem;

        h5 {
            text-align: left;
            color: $white;
        }

        .form-control {
            margin: 0;
        }

        .btn.search_btn {
            width: 6em;
            background-color: $primary;
            color: $white;
            @include tran(.3s, ease);

            &:hover {
                filter: brightness(1.2);
            }
        }


    }

    .offcanvas-top {
        height: 170px;
        background-color: $cyan;
    }

    .offcanvas-header {
        // justify-content: flex-end;
        padding: 0 20px;

        button.btn-close {
            position: absolute;
            right: 2rem;
            z-index: 9;
            font-size: 1em;
            color: $white !important;
        }
    }

    .offcanvas-backdrop {
        z-index: -3;
    }
}

.wrapper {
    margin-top: 100px;
}

.pageTab {
    justify-content: center;

    a {
        text-decoration: none;
    }

    @include media-breakpoint-up(md) {
        li {
            position: relative;
            margin: 1em;

            button {
                padding: 1em 2em;

                &.active:after {
                    position: absolute;
                    left: calc(50% - 25px);
                    content: "";
                    @include triangle(top, $white, 20px, 50px);
                    margin-top: 30px;
                }
            }
        }
    }

}



.mainInfo {
    background-color: $white;
    padding: 4%;
    margin-bottom: 10px;
    border-radius: 5px;

    @include media-breakpoint-down(md) {
        padding: 4% 5vw;

    }

    .newsList {
        .article_title {
            display: flex;
            margin-bottom: 1em;

            &::before {
                content: "";
                width: .5em;
                height: 1.1em;
                line-height: 24px;
                background-color: $cyan;
                display: inline-block;
                vertical-align: middle;
                margin-right: .3em;
            }
        }


    }

    .articleTitle {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        // border-bottom: 1px solid $gray-200;

        h1 {
            color: $primary;
            margin-bottom: .5em;
        }

        .articleNote {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $gray-700;

            b {
                font-weight: normal;
                margin-right: 1rem;
            }

            i {
                font-style: normal;
                color: $gray-500;
                font-size: .8rem;
            }

            a {
                width: 20%;
                color: $white;

                // display: block;
                i {
                    opacity: 1;
                    display: inline-block;
                    margin: 0 .5rem;
                    font-size: 1.6rem;
                }

                &:hover {
                    i.bi-line {
                        color: $line;
                    }

                    i.bi-facebook {
                        color: $facebook;
                    }
                }
            }
        }
    }

    .infoArea {
        .articleTag {
            padding: 1.5rem 1rem;
            border: 1px solid $cyan;

            h5 {
                line-height: 2;
            }

            .tagList {
                margin-left: 0;
                margin-bottom: 2rem;
            }

            .author {
                display: flex;
                align-items: center;

                .pic {
                    display: flex;
                    align-items: center;
                    width: 70px;
                    height: 70px;
                    margin-right: 1rem;
                    border-radius: 50px;
                    border: 1px solid $gray-300;

                    img {
                        width: 100%;
                        border-radius: 50px;
                    }
                }
            }

            p {
                font-size: .8rem;
                margin-top: 1rem;
                color: $gray-700;
                text-align: justify;
                text-align-last: left;
                line-height: 1.8;
            }
        }

        .article_con {
            // width: 700px;
            // @include media-breakpoint-down(xl){
            //     width: 100%;
            // }
            padding-right: 5%;

            p {
                font-size: 1rem;
                color: $gray-700;
                line-height: 2;
                // @include text-justify();
                text-align: justify;
                text-align-last: left;
            }

            img {
                width: 100%;
            }
        }
    }

    .otherArticle {
        position: relative;
        margin-top: 3rem;
        padding: 1rem;
        border: 1px solid $cyan;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            // z-index: -1;
            content: "";
            display: block;
            width: 20vw;
            height: 20%;
            background-color: $cyan;
        }

        &:after {
            position: absolute;
            bottom: 0;
            right: 0;
            // z-index: -1;
            content: "";
            display: block;
            width: 20vw;
            height: 20%;
            background-color: $cyan;
        }

        &>div {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: $white;
            z-index: 2;
            padding: 1rem .5rem;
        }

        h4 {
            color: $primary;
            padding: 1rem 1.5rem;
        }

        .otherList {
            padding: .5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            margin-bottom: 0;

            a {
                text-decoration: none;
                color: $gray-800;
                margin-bottom: 1rem;

                &:hover {
                    color: $primary;
                    filter: brightness(1.1);
                }

                h5 {
                    padding-left: 2rem;
                    display: inline-block;
                    @include text-ellipsis (100%, 1);

                    i {
                        color: $primary;
                        margin-right: .5rem;
                        margin-left: -1em;

                    }
                }
            }

            p {
                font-size: .9rem;
                padding-left: 2rem;
                color: $gray-700;
                @include text-ellipsis (100%, 2);
            }

            &.list_hh {
                figure {
                    display: flex;
                    margin-bottom: .5rem;

                    .pic {
                        width: 15%;
                        height: 73px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .text {
                        width: 85%;
                    }

                    h5,
                    p {
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }

                    @include media-breakpoint-down(xl) {
                        .pic {
                            width: 20%;
                        }

                        .text {
                            width: 80%;
                        }
                    }

                    @include media-breakpoint-down(lg) {
                        .pic {
                            width: 30%;
                        }

                        .text {
                            width: 70%;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        .pic {
                            width: 35%;
                            height: 73px;
                        }

                        .text {
                            width: 65%;
                        }
                    }
                }


            }


        }

        @include media-breakpoint-down(md) {
            padding: 10px;

            h4 {
                padding: .3rem 1rem;
            }

            &>div {
                padding: .8rem 0;
            }

            .otherList {
                padding-left: 1rem;
            }
        }
    }

    &.articleContent {
        padding: 2.5% 10%;

        @include media-breakpoint-down(md) {
            padding: 5%;

        }
    }

    &.article_s {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding: 2.5% 5%;

        @include media-breakpoint-down(lg) {
            width: 100%;
            padding: 5%;
        }

        .article_con {
            padding-right: 0;
        }
    }

    &.about_page {
        padding-top: 0;
        // padding-right: 0;
        background-color: transparent !important;

        .articleTitle {
            padding-top: 3%;
            margin-bottom: 5%;

            h5 {
                text-align: center;
                color: $cyan;
                // margin-bottom: 1rem;
            }

            h1 {
                color: $blue-bg;
                text-align: center;
                // margin-bottom: 3rem;
                font-size: 2rem;
            }

            img {
                width: 100%;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 0;
                margin-bottom: 0;

            }

            @media (min-width: 1453px) {
                padding-top: 1%;
                margin-bottom: 1%;
            }

            @media (min-width: 1669px) {
                padding-top: 5%;
                margin-bottom: 3%;
            }

            @include media-breakpoint-down(lg) {
                padding-top: 1.8%;

                h1 {
                    font-size: 1.5rem !important;
                }
            }

            @include media-breakpoint-down(sm) {
                h5 {
                    font-size: .9rem;
                }

                h1 {
                    font-size: 1.2rem !important;
                }
            }
        }

        .article_con.about_con {
            background-color: $white;
            padding: 4%;

            p {
                font-size: .9rem;
            }

            h3 {
                font-size: 1.3rem;
                color: $primary;
                margin-bottom: 1em;
            }

            h5 {
                font-size: 1.1rem;
                color: $gray-800;
            }

            ul {
                li {
                    font-size: .9rem;
                    font-weight: 300;
                    color: $gray-700;
                    list-style: none;
                    margin-bottom: .5em;

                    span {
                        padding-left: 1em;
                        display: block;
                    }

                }
            }

            .about_info {
                padding: 0 !important;
                margin: 4vw 0;

                .info_txt {
                    // margin: 1rem 0;
                    font-size: .9rem;

                    h2 {
                        color: $primary;
                        font-weight: bold;

                        span {
                            color: $white;
                            font-size: 4rem;
                            font-style: italic;
                            text-shadow: -1px -1px 0 $primary,
                                1px -1px 0 $primary,
                                -1px 1px 0 $primary,
                                1px 1px 0 $primary;
                        }
                    }


                    p {
                        position: relative;
                        padding: 3em;

                        &::before {
                            position: absolute;
                            top: 2.5em;
                            left: -20px;
                            content: "";
                            width: 100px;
                            height: 1px;
                            background-color: $cyan;
                            @include rotate (-45);

                        }

                        &::after {
                            position: absolute;
                            bottom: 4em;
                            right: -20px;
                            content: "";
                            width: 100px;
                            height: 1px;
                            background-color: $cyan;
                            @include rotate (-45);

                        }
                    }

                    @include media-breakpoint-down(xl) {
                        p {
                            padding: 0;
                            font-size: .8rem;

                            // line-height: 1;
                            // background-color: #f00;
                            &::after,
                            &::before {
                                display: none;
                            }
                        }
                    }

                    @include media-breakpoint-down(lg) {
                        margin: 1rem 0;

                    }
                }
            }
        }
    }

}

.informationList {

    // padding: 0 4%;
    .infoListItem {
        margin-bottom: 1.5rem;
        padding: 1.5rem;
        border: 1px solid $cyan;

        h5 {
            color: $primary;
            @include text-ellipsis (100%, 2);
        }

        p {
            color: $gray-700;
            font-size: .9rem;
            @include text-ellipsis (100%, 3);
        }

        .btn {
            color: $white;
        }

    }
}

.searchList {
    padding: 0 4%;

    .searchItem {
        margin-bottom: 1.5rem;
        padding: 1.5rem;
        border: 1px solid $cyan;
        @include tran(.3s, ease);

        &:hover {
            background-color: lighten($color: $cyan-100, $amount: 6%);

            // background-color:$cyan-100;
            p {
                color: $primary;
            }
        }

        a {
            // color: $gray-700;
            text-decoration: none;


        }

        h5 {
            color: $primary;
            @include text-ellipsis (100%, 1);
        }

        p {
            color: $gray-700;
            font-size: .9rem;
            @include text-ellipsis (100%, 2);
        }

        .btn {
            color: $white;
        }

    }
}

.pageNav {
    width: 80%;
    border: 1px solid $cyan;
    margin-bottom: 1rem;

    a {
        text-decoration: none;
    }

    .navTitle {
        display: none;

        div {
            display: flex;
            justify-content: space-between;
            font-size: 1.2rem;
            padding: .5rem 1.1rem;
            color: $primary;
            cursor: pointer;
        }

        // filter: brightness(1.2);
    }

    @include media-breakpoint-down(lg) {
        width: 100%;
    }

    @include media-breakpoint-down(md) {

        .navTitle {
            display: block;

        }
    }

    //子選單
    &>ul {
        display: block;
        margin-left: 0;
        padding: 1em 0;

        &>li {
            list-style: none;
            overflow: hidden;

            &>a {
                position: relative;
                padding: .4em 1em;
                position: relative;
                display: block;
                color: $gray-800;
                font-weight: bold;
                letter-spacing: .1em;
                overflow: hidden;
                @include tran(.3s, ease);

                span {
                    position: relative;
                    padding: .1em .3em;
                    background-color: $white;
                    z-index: 2;

                }

                &:hover {
                    filter: brightness(1.2);
                }

                &::after {
                    position: absolute;
                    left: 0;
                    // z-index: 1;
                    content: "||||||||||||||||||||||||||||||||||||||||||||";
                    // letter-spacing: em;
                    opacity: 0;
                    font-weight: normal;
                }

                &.active {
                    color: $primary;

                    &::after {
                        opacity: .6;
                    }
                }

                @include media-breakpoint-down(lg) {
                    &::after {
                        display: none;
                    }
                }
            }

            //子選單內層的選單
            &>ul {
                margin-left: 0;
                margin-top: .4em;
                padding-left: 1em;

                li {
                    padding: .4em 0;
                    list-style: none;
                    font-size: .9rem;
                    color: $gray-600;
                    cursor: pointer;

                    &:hover {
                        filter: brightness(1.2);
                    }

                    &>a {
                        color: $gray-700;
                    }

                    span.active {
                        color: darken($color: $cyan, $amount: 15%);
                    }
                }
            }


        }

        @include media-breakpoint-down(md) {
            display: none;
            border-top: 1px solid $cyan;
        }
    }
}

.card {
    // position: relative;
    background-color: $gray-100;
    border: 1px solid $gray-200;

    .card-body {
        // align-items: center;
        padding: 1.3em 1.8em;

    }

    h5 {
        font-size: 1.15rem;
        font-weight: bold;
        color: $primary;
        @include text-ellipsis (100%, 1);
    }

    p {
        color: $gray-700;
        @include text-ellipsis (100%, 2);
        font-weight: 300;
    }

    .btn {
        color: $white;
        background-color: $cyan;

        &:hover {
            background-color: $primary;
        }
    }

    .rank {
        position: absolute;
        top: -2px;
        left: -2px;
        width: 30px;
        height: 30px;
        z-index: 5;
        font-size: 1.5rem;
        font-weight: bold;
        font-style: italic;
        color: $white;
        text-align: center;
        padding-top: 3px;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            content: "";
            width: 0;
            height: 0;
            border-color: transparent $red;
            border-width: 0px 0px 55px 55px;
            border-style: solid;
            border-radius: 5px;

        }

    }

    &.card_hh {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // align-items: stretch;
        flex-grow: 1;
        margin-bottom: 1rem;
        padding-left: 0;
        // overflow: hidden;

        .card_img {
            display: flex;
            // align-items: stretch;
            width: 37%;
            height: 12rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 63%;
            padding: .8em 1.5em;

            .btn {
                width: 8em;
            }
        }

        // @include media-breakpoint-down(lg) {
        //     .card_img{
        //         width: 100%;
        //         height: 100%;
        //     }
        // }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding-left: 0;
            padding-right: 0;

            .card_img {
                display: flex;
                align-items: center;
                width: 100%;
                height: 30vw;
                // overflow: hidden;

                img {
                    width: 100%;
                }
            }

            .card-body {
                width: 100%;

                .btn {
                    margin-left: calc(50% - 4em);
                }
            }

        }

        @include media-breakpoint-down(sm) {
            .card_img {
                height: 40vw;
            }
        }
    }

    &.card_vv {
        // width: 30vw;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;

        .card-body {
            border-top: 1px solid $white;
        }

        .card_img {
            display: flex;
            align-items: center;
            width: 100%;
            height: 9rem;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


        .btn {
            width: 8em;
            margin-left: calc(50% - 4em);
        }

        &.card_yt {
            width: 100%;

            h5 {

                @include text-ellipsis (100%, 2);
            }

            .card_video {
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;
                /* 16:9 的比例 (9 ÷ 16 = 0.5625) */
                overflow: hidden;

                iframe {
                    // width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 0;
                    /* 確保沒有邊框 */
                }
            }

            p {
                line-height: 1;
                margin-bottom: .35em;
            }

            .card-body {
                border-top: 0;
            }
        }
    }


}



// .infoSaerch{

// }

.searchForm {

    // padding-top: 20px;
    .searchGroup {
        width: 100%;
        margin-top: 2em;

        &>div:first-child {
            width: 70%;
        }

        input,
        input.form-control {
            border-radius: 0;
            border: 1px solid $primary;
            margin-right: 0 !important;
        }

        button {
            background-color: $primary;
            color: $white;
        }
    }
}

.tagList {
    display: flex;
    flex-wrap: wrap;
    margin-left: .5em;

    .tag_item {
        color: $cyan;
        border: 1px solid $cyan;
        padding: .2em 1em;
        border-radius: 50px;
        font-size: .8rem;
        margin-right: .5em;
        margin-bottom: .7em;

        i {
            display: inline-block;
            // width: 1em;
            height: 100%;
            // font-size: 1.5em;
            // background-color: #f00;
            cursor: pointer;
            text-align: center;
        }
    }
}

.tagGroup {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 1.5em;

    span.data_num {
        color: $primary;
        margin: 0 .5em;
    }



    .btn_clearAll {
        border-radius: 50px;
        padding: .2em 1em;
    }

}


.downloadBtn {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 85%;
    border: 1px solid $cyan;
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &>div {
        padding: .7rem;
        flex-grow: 1;
        box-sizing: border-box;

    }

    &>div:first-child {
        width: 100%;
    }

    &>div:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.5rem;
        min-height: 100%;
        background-color: $cyan;
        text-decoration: none;
        padding: 0;

        &>a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: .5rem;
            text-decoration: none;
        }

        @include tran(.3s, ease);

        &:hover {
            background-color: $primary;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            min-height: inherit;

        }
    }


    i {
        // display: block;
        flex-grow: 1;
        width: 1em;
        // height: 100%;
        font-size: 200%;
        // margin-left: 1rem;
        color: $white;
        cursor: pointer;
        text-align: center;

        @include media-breakpoint-down(md) {
            font-size: 1.5rem;
            line-height: 15px;
            vertical-align: middle;

            &:before {
                vertical-align: top;
                line-height: 15px;
            }

            &::after {
                content: "下載";
                font-size: .8em;
                font-style: normal;
                line-height: 15px;
                margin-left: .5em;
                vertical-align: top;
            }
        }
    }

    h5 {
        color: $gray-800;
    }

    p {
        color: $gray-500;
        margin-bottom: 0;
    }

}

.pagination .page-item {
    margin-top: 2em;

    .page-link {
        // border: 0;
        width: 44px;
        height: 42px;
        border: 1px solid $cyan;
        margin: 0 5px;
        text-align: center;
        padding: .46rem .75rem;
        @include tran(.3s, ease);

        &:hover {
            background-color: $cyan-100;
            color: $primary;
        }
    }

    &.goto_page {
        .page-link {
            border: 0;
            border: 1px solid transparent;

            &:hover {
                color: $primary;
                background-color: transparent;
                border: 1px solid $cyan;
            }
        }
    }

    &.active {
        .page-link {
            &:hover {
                background-color: $primary;
                color: $white;
            }
        }

    }
}

footer {
    position: relative;
    z-index: 3;
}

.footerAll {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 50px;
    padding: 60px 10%;
    background-color: $white;
    font-size: 1rem;
    border-top: 1px solid $cyan-200;

    @media (max-width: 1470px) {
        padding: 60px 3%;
    }

    @media (max-width: 1245px) {
        padding: 60px 2%;
    }

    @include media-breakpoint-down(xl) {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        padding: 60px 5%;

        .footerInfo {
            order: 1;
        }

        .footerLinks {
            order: 3;
            margin-left: -2vw;
            margin-bottom: 2em;
        }

        .footerOthers {
            width: fit-content;
            order: 2;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: .9em;
        padding: 40px 30px;

        .footerLinks {
            order: 2;
            margin-left: 2vw;
        }

        .footerOthers {
            order: 3;
        }
    }

    h5 {
        font-size: 120%;
        font-style: normal;
        color: $primary;

        &.rotate180 {
            i::before {
                animation: rrroate .3s linear 1 forwards;
            }
        }

        @include media-breakpoint-up(md) {
            i {
                display: none;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            font-style: normal;
            line-height: 2;
        }
    }

    a {
        text-decoration: none;
        color: $gray-700;

        // font-size: 1rem;
        // @include tran(.3s, ease);
        // &:after{
        //     content: "";
        //     width: 100%;
        //     height: 1px;
        //     margin-top: -.5em;
        //     background-color: $cyan;
        //     display: inline;
        //     opacity: .2;
        //     @include tran(.3s, ease);
        // }
        &:hover {
            color: $gray-900;
            text-decoration: underline;
            // &:after{
            //     opacity: 1;

            // }

        }
    }

    .footerInfo {
        // font-size: 1rem;
        margin-bottom: 2em;

        &>ul {

            // margin: 0 2em;
            li {
                // font-size: 1rem;
                color: $gray-800;
                line-height: 1.8;

                span {
                    margin-right: .5em;
                }

                img {
                    height: 2em;
                }

            }
        }

        .foot_logo {
            margin-bottom: .8em;
        }

    }

    .footerLinks {
        display: flex;

        &>div {
            margin: 0 2vw;

            &>ul {
                margin: 0;
                padding: 0;

                li {
                    // font-size: .8rem;
                    line-height: 2;
                }

            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;

            &>div {
                margin-bottom: 1.5em;

                &>ul {
                    display: none;
                }
            }
        }

    }

    .footerOthers {
        .foot_icons {
            width: 100%;
            // background-color: $gray-100;
            border: 1px solid $gray-300;
            padding: 10px 25px 10px 10px;
            border-radius: 10px;
            margin-bottom: .5em;

            img {
                height: 3em;
            }

            a {
                &:hover {
                    filter: brightness(1.2);
                }
            }

            &>img {
                height: 5em;
                margin-right: 12px;
            }
        }

        .unit {
            width: 100%;
            border: 1px solid $gray-300;
            padding: 10px 20px;
            border-radius: 10px;

            div {
                margin: 0;
            }

            span {
                font-size: 12px;
                margin-right: .5em;
            }

            i {
                display: inline-block;

                &>img {
                    // width: 4em;
                    height: auto;

                    &.logo2_1 {
                        width: 100px;
                    }

                    &.logo2_2 {
                        width: 170px;
                        margin-right: 5px;
                    }

                    &.logo2_3 {
                        width: 80px;
                    }
                }

            }
        }

    }
}

.copyright {
    width: 100%;
    text-align: center;
    font-size: 80%;
    font-style: normal;
    background-color: $gray-900;
    color: $white;
    padding: .7em;
}